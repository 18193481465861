.signup_container{
    padding: 3.5em 5em;

    margin: 0 auto;
    width: 25%;
    text-align: center;
}
.signup_container > h3{
   
    font-size: 2rem;
    font-weight: 700;
    line-height: 41.6px;
    letter-spacing: -0.005em;


}

.signup_form h4{
    text-align: left;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 22.4px;
    margin-bottom: 4px;
}
.signup_name{
    display: flex; 
    gap: 31px;
}
.name_input{
    width: 165px;
}

.signup_name  input{
    width: 100%;
    height: 44px;
    padding: 0em 0.5em;
    border-radius: 6px;
    border: 1px solid #DDD8D0
}
.signup_email{
    margin-top: 16px;
}

.signup_email > div{
    width: 365px;
    border: 1px solid #DDD8D0;
    display: flex;
    background-color: #FFFFFF;
    border-radius: 6px;
    gap: 8px;
    padding: 0em 0em 0em 0.5em;
}
.signup_email input{
    width: 100%;
    height: 44px;
    border: none;
    padding: 0em 0.5em;
    
}
.signup_pass{
    margin-top: 16px;
}
.signup_pass > div{
    width: 360px;
    border: 1px solid #DDD8D0;
    display: flex;
    background-color: #FFFFFF;
    border-radius: 6px;
    gap: 8px;
    padding: 0em 0.5em 0em 0.5em;
}
.signup_pass input{
    width: 100%;
    height: 44px;
    border: none;
    padding: 0em 0.5em;
    
}

.helper_text{
  
font-size: 0.875rem;
font-weight: 400;
line-height: 22.4px;
letter-spacing: -0.015em;
text-align: left;
margin: 8px 0px 16px;
color: #667085;
}

.existing_account{
    display: flex;
    margin: 20px 0px 32px 0px;
    gap: 10px;
    justify-content: center;
}
.existing_account > h4:nth-child(1){
    
    font-size: 1rem;
    font-weight: 400;
    line-height: 26.4px;
    color:#4B4F57;
}
.existing_account > h4:nth-child(2){
   
    font-size: 1rem;
    font-weight: 500;
    line-height: 26.4px;
    color:#007EFF;
    text-align: center;
}