.trending-main{
    padding:0em 2em 1.5em 2.5em;
    background: #F6F6F6;
}
.trending-cont{
        
    height: 350px;
    padding:1.5em 1.5em;
    border-radius: 12px;
    background: #FFFFFF;

}
.trending-header{
   
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}
.trending-header > h4{
        
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.01em;
    color: #000000;

}
.trending-arrow{
    display: flex;
    gap: 24px;
}
.trending-two{
    display: flex;
    gap: 24px;
}
.trending-two-box.hidden{
    display: none;
}
.trending-two-box{ 
    width: 19.7%;
}
.trending-two-img{
    width: 220px;
    height: 150px;
    overflow: hidden;
    margin-bottom: 16px;
}
.trending-two-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px 4px 0px 0px;
    border: 1px solid #ECE9E4;
    
}
.trending-two-type{
    display: flex;
    gap: 8px;
    margin-bottom: 8px;
    align-items: center;
}

.trending-two-type > p{
    // font-family: Inter;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 19.2px;
    letter-spacing: 0.1em;
    color: #77787C;

}
.trending-two-content > p{
    
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 22.4px;
    color:#101928;
   
}
.trending-updated{
    // font-family: Inter;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 18.6px;
    color:#77787C;
    margin-top: 8px;
  

}
.title-cont{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
