.get_started_header h2{
        
    font-size: 2.25rem;
    font-weight: 600;
    line-height: 46.8px;
    letter-spacing: -0.01em;
    color: #000000;
    margin-bottom: 20px;

}
.get_started_header p{

    font-size: 1rem;
    font-weight: 400;
    line-height: 26.4px;
    color: #4B4F57;
    margin-bottom: 24px;

}