.pdf-backgd{
    background: #000000;
    padding: 5em 5em 0em;
}
.pdf-main-cont{
    background: #FFFFFF;
    border-radius: 20px 20px 0px 0px;
  
}
.pdf-cont-header{
    padding: 1em 3.25em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #F3F2EF;
}

.pdf-iframe-cont{
    width: 65%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFFFFF;
    padding: 40px;
}
.pdf-header-left{
    display: flex;
    gap: 14px;
}
.pdf-header-left > div h4{
    margin-bottom: 2px;
    font-size: 1rem;
    font-weight: 500;
    line-height: 26.4px;

}
.pdf-left-tag{
    display: flex;
    gap: 16px;
}

.pdf-left-tag li {

    list-style: none;
    position: relative;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.015em;
    color: #9CA0A5;
}
  
.pdf-left-tag  li::before {
    content: '•';
    color: #000000;
    left: -6px;
}

.pdf-left-tag p {
    
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.015em;
    color: #77787C;    
}


.pdf-header-btn{
    display: flex;
    gap: 12px;
}
.pdf-header-close-btn{
   
    padding: .5em 1em;
    border-radius: 8px;
    border: 1px solid #DDD8D0;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 27.4px;
    color: #4B4F57;
    height: 29px;
    cursor: pointer;
    
}
.pdf-header-report-btn{
    display: flex;
    align-items: center;
    height: 25.5px;
    gap: 8px;
    padding: .5em .75rem;
    border-radius: 8px;
    border: 2px solid #D42620;
     
}


.pdf-header-report-btn h4{

    font-size: 0.875rem;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: -0.005em;
    color: #D42620;
    cursor: pointer;
}

.pdf-section{
    display: flex;
    justify-content: center;
    padding: 2em 1.5em;
    align-items: flex-start;
}


.pdf-icons{
    width: 18px;
    height: 18px;
    padding: 8px;
    border-radius: 20px;
    border: 1px solid #ECE9E4;
    margin-bottom: 20px;

}

.pdf-modal-cont > h4{

    font-size: 1.625rem;
    font-weight: 600;
    line-height: 36.4px;
    text-align: center;
    color: #000000;  
    margin-bottom: 32px;

}
.pdf-modal-cont > p{
    font-size: 0.875rem;
    font-weight: 500;
    color: #101928;
    margin-bottom: 32px;
}

.pdf-modal-cont > textarea{
    width: 95%;
    height: 125px;
    border: 1px solid #ECE9E4;
    border-radius: 8px;
    padding: 1em;
    margin-bottom: 32px;
   
}