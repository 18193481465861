.admin-resc-table-cont{
    padding: 0em 2.5em;
}
.admin-resc-head{
    text-align: left;
    padding: 0.75rem;
}
.admin-resc-head > tr{
    height: 46px;
    
    background: #FBFBF9;
    
}
.admin-resc-head > tr th{
   
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 22.4px;
    color: #000000;
}
.admin-resc-body > tr td{
    padding:  0.75em 0em;
}
.admin-col-one{

    display: flex;
    gap: 20px;
    align-items: center;
    padding-left: 12px;

}
.admin-col-one p {
        
    font-size: 1rem;
    font-weight: 400;
    line-height: 26.4px;
    color: #101928;
    cursor: pointer;
   
}
.admin-col-two {

    display: flex;
    gap: 8px;
    align-items: center;
}

.admin-col-two p {
    
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.015em;   
}
.admin-col-status{
      
    font-size: 14px;
    font-weight: 500;
    line-height: 22.4px;
    padding:2px 12px;
    width: fit-content;
    border-radius:16px;

}
.table-btn-view button{
    width: 66px;
    height: 44px;
    padding: .5em 1em;
    border-radius: 8px;
    border: 1px solid #DDD8D0;
    background: #FFFFFF;
}