.profile_card_profile_cont{
    background-color:#FFFFFF ;
    border-radius: 12px;
}
.profile_card_header{
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
}

.profile_card_profile_cont > h4{
        
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.01em;
    color: #000000;
    padding: 1.25em 1.5em;
    border-bottom: 1px solid #ECE9E4;
}
.profile_card_profile{
    padding: 1.25em 1.5em;
    display: flex;
    gap: 32px;
}
.profile_card_name{
    border-right: 1px solid #ECE9E4;
    width: 35%;
}
.profile_card_name > h4{

    font-size: 1rem;
    font-weight: 600;
    line-height: 26.4px;
    letter-spacing: 0.02em;
    color: #101928;
    margin-top: 12px;
}
.profile_card_name > p{
   
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.015em;
    margin-top: 4px;
    color: #77787C;

}
.profile_card_name > div{
    width: 64px;
    height: 64px;
}
.profile_card_name img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 33px;
}
.profile_card_grid{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    column-gap: 60px;
    row-gap: 8px;
    margin-bottom: 30px;
}
.profile_card_grid  p{
  
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.015em;
    text-align: left;
    color: #77787C;
    margin-bottom: 4px;
}

.color_consumer{
    background: #E7F6EC;
    width: 70px;
    height:26px;
    padding: 0.125em 0.75em;
    border-radius: 12px;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 22.4px;
    color: #036B26;
    text-align: center;
}
.profile_card_pref > p{

    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.015em;
    color:#77787C ;
    margin-bottom: 12px;

}
.profile_card_pref > li{
   
    font-size: 1rem;
    font-weight: 500;
    line-height: 26.4px;
    color: #101928;
}