.contributor-sdb-cont{
    width: 256px;
    height: 100vh;
    padding: 2.25em 0.5em 0em;
    background: rgba(0, 0, 0, 1);
    border-right: 1px solid #E4E7EC;
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    
}
.sidebar-content{
    flex-grow: 1; 
}
.contributor-sdb-cont-logo{
    padding: 0em 1em;
    margin-bottom: 20px;
}
.contributor-sdb-cont-pg{
    display: flex;
    gap: 12px;
    align-items: center;
    width: 224px;
    margin-bottom: 6px;
    height: 22px;
    padding: 0.75em 1em;
    border-radius: 8px;
    background: #000000;
    color: rgba(243, 242, 239, 1);
}
.contributor-sdb-cont-pg.alt{

    background: #272B32;
    color: #FFFFFF;
}
.contributor-sdb-cont-pg > h4{
  
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22.4px;
    
}
.contributor-sdb-cont-pg > div{
    height: 20px;
}
.contributor-sdb-cont-sts{
    display: flex;
    justify-content: space-between;
    margin: 40px 0px 28px;
    padding: 0em 1em;
    align-items: center;
}
.contributor-sdb-cont-sts > div{
    display: flex;
    gap: 12px;
}
.contributor-sdb-cont-sts h4{
 
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22.4px;
    cursor: pointer;
    color: #FFFFFF;
}
.contributor-sdb-cont-prf{
    padding-left: 3em;
}
.contributor-sdb-cont-prf > h4{
    
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.015em;
    color: #FFFFFF;
    margin-bottom: 10px;
    padding: 0.75em 0em;


}
.contributor-sdb-cont-prf.alt h4{
    padding: 0.75em 0em;
}
.contributor-sdb-cont-prf.alt{
    
    background: #272B32;
}
.contributor-sdb-logout{
    margin-bottom: 40px;
    display:flex;
    justify-content: space-between;;
}
.contributor-sdb-logout-img  {
    width: 40px;
    height: 40px;
   
}
.contributor-sdb-logout-mail{
    max-width: 160px;
    color: #FFFFFF;
    word-break: break-all;
   
}
.contributor-sdb-logout-mail > h4{
    font-weight: 500;
}

.contributor-sdb-logout-img > img{
    width: 100%;
    height: 100%;
    border-radius: 50px;
    object-fit: cover;
}
.contributor-sdb-logout > div{
    display: flex;
    gap: 12px;
    align-items:center;
}
.contributor-sdb-logout-icon{
    width: 18px;
}
.contributor-sdb-logout-icon img{
    width: 100%;
}