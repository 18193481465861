.contb-header-cont{
    padding: 2.5em 2.5em 1.5em;
}
.contb-header{
       
    font-size: 2.25rem;
    font-weight: 700;;
    line-height: 46.8px;
    letter-spacing: -0.003em;
    color: #000000;
   
    
}
.contb-header-box{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
}
.contb-header-box > div {
    display: flex;
    gap: 12px;
    align-items: center;
}
.new-contrbtn{
    display: flex;
    align-items: center;
    height: 44px;
    padding: 0.5em 0.75em;
    border-radius: 8px;
    background-color: #000000;
    border: 1px solid #000000;
    outline: none;
    gap: 8px;
}
.new-contrbtn p{

    font-size: 0.875rem;
    font-weight: 500;
    line-height: 22.4px;
    color: #FFFFFF;

}
.contb-header-search{
    display: flex;
    align-items: center;
    background: #F3F2EF;
    width: 300px;
    height: 22px;
    padding: .6rem .75rem;
    border-radius: 6px;
    gap: 8px;

}
.contb-header-search input{
    border: none;
    background: #F3F2EF;
}
.contb-type{
    display: flex;
}
.contb-type :nth-child(1){
    width: fit-content;
    height: 22px;
    padding: .6em 1em;
    border-radius: 8px 0px 0px 8px;
    border: 1px solid #DDD8D0;
    cursor: pointer; 
    font-size: 0.875rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    


}
.contb-type :nth-child(2){
    width: fit-content;
    height: 22px;
    padding: .6em 1em;
    border: 1px solid #DDD8D0 ;
    cursor: pointer;
    font-size: 0.875rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    
}
.contb-type :nth-child(3){
    width: fit-content;
    height: 22px;
    padding: .6em 1em;
    border-radius: 0px 8px 8px 0px;
    border: 1px solid #DDD8D0 ;
    cursor: pointer;
    font-size: 0.875rem;
    font-weight: 500;
    display: flex;
    align-items: center;
  

}
.contb-type-active{
    background: #000000;
    color: #FFFFFF;
}
.contb-type-inactive{
    background: #FFFFFF;
    color: #77787C;
}

.contb-type-box{
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: space-between;
}
.contb-filter{
    display: flex;
    align-items: center;
    gap: 4px;
}
.contb-filter h4{
      
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 22.4px;
    color: #77787C;
}
.cont_modal_header{
    text-align: center;
    margin-bottom: 32px;
}
.cont_modal_content{
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    // align-items: center;
}
.cont_modal_header h2{
    font-size: 28px;
    font-weight: 600;
    line-height: 36.4px;
    margin-bottom: 12px;
}

.cont_modal_header p{
    
    font-size: 1rem;
    font-weight: 400;
    line-height: 26.4px;
   

}

.cont_modal_content > div{
    display: flex;
    gap: 12px;
}

.cont_modal_content h4{
       
    font-size: 1rem;
    font-weight: 500;
    line-height: 26.4px;
    color: #101928;
}

.cont_modal_content p{
   
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.015em;
    color: #9CA0A5;
}
.cont_modal_sub{
        
    font-size: 1rem;
    font-weight: 500;
    line-height: 26.4px;
    color: #101928;
    margin-bottom: 24px;
}
.cont_modal_button{
    
    height:56px;
    padding: 1em 1.5em;
    border-radius: 12px;
    width: 100%;
    background: #007EFF;
    border: 1px solid #007EFF;
    color: #FFFFFF;
    font-size: 1rem;
    font-weight: 500;
    line-height: 26.4px;


}