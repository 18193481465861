.wrapper {
  height: 336px;
  background: #fff;
  border-radius: 10px;
  position: absolute;
  padding: 0em 1em;
  box-shadow: 0px 4px 9px -2px rgba(0, 0, 0, 0.2);
  z-index: 2;

}
.wrapper header {
  display: flex;
  align-items: center;
  padding: 10px 0px 5px;
  justify-content: space-between;
  
}
.currentDate{
  
  font-size: 1rem;
  font-weight: 600;
  line-height: 32px;

}
header .icons {
  display: flex;
}
header .icons span {

  width: 38px;
  margin: 0 1px;
  cursor: pointer;
  color: #878787;
  text-align: center;
  line-height: 11px;
  font-size: 1.9rem;
  user-select: none;

}
.today {
  color: #000000;
}
.icons span:last-child {
  margin-right: -10px;
}

header .current-date {
  font-size: 1.45rem;
  font-weight: 500;
}
.calendar {
  padding: 15px 0px 20px;
}
.calendar ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  text-align: center;
  
}
.calendar .days {
  margin-bottom: 20px;
}
.calendar li {
  color: #333;
  width: calc(100% / 7);
  font-size: 1rem;
}
.calendar .weeks li {
  font-weight: 600;
  font-size: 0.875rem;
  cursor: default;
  padding-bottom: .5em;
  border-bottom: 1px solid #cccccc;
}
.calendar .days li {
  z-index: 1;
  font-weight: 400;
  cursor: pointer;
  position: relative;
  margin-top: 15px;
  line-height: 24px;
  ;
}
.days li.inactive {
  color: #aaa;
}
.days li.notCurrent {
  color: #aaa;
  visibility: hidden;
}
.days li.active {
  color: #fff
}
.days li.inactive .unselectable {
  color: #aaa;
}
.days li::before {
  position: absolute;
  content: "";
  left: 50%;
  top: 50%;
  height: 25px;
  width: 25px;
  z-index: -1;

  transform: translate(-50%, -50%);
}
.days li.active::before {
  background: #007EFF;
  color: #fff;
  border-radius: 24px;
}

.days li:not(.active):hover::before {
  background: #f2f2f2;
}

.arrowbtn {
  height: 15px;
  width: 15px;
}
