.custom_select_container {
    position: relative;
    border-radius: 6px ;
    border: 1px solid #DDD8D0;
    background: #FFFFFF;
    cursor: pointer;
}
.custom_selected_option{
  
    display: flex;
    justify-content: space-between;
    
}

.custom_country_cont h4{
    margin-bottom: 0px;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.015em;


}

.custom_country_select{
    display: flex;
    align-items: center;
    gap: 8px;
    padding: .5em 1em;
}
.custom_country_select:hover{
    background: #F9F9F9
}

.custom_country_cont{
    position: absolute;
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
    list-style-type: none;
    width: 100%;
    z-index: 1;
    font-size: 1rem;
    overflow-y: auto;
    padding: 0.5em 0em;
    height: 300px;
    
  }
  .custom_country_cont hr{
    border-top: 1px solid #F3F2EF;
  }

  .custom_country_cont h3{
   
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 22.4px;
    letter-spacing: -0.015em;
    color: #000000;
   
    padding: .5em 1em;
  }


  .custom_selected_placeholder{
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.015em;
    color: #B8B3A7;
  }
 
  .custom_list{
    font-size: 0.875rem;
  }
  .option_header{
    display: flex;
    align-items: center;
  }
  .custom_list_country > div{
    display: flex;
    align-items: center;
   

  }
  .custom_list_country > input{
    width: 20px;
  }

  .custom_selected_location{
    display: flex;
    align-items: center;
    width: fit-content;
    gap: 8px;
    height: 15px;
    padding: 0.375em 0.75em;
    border-radius: 8px;
    border: 1px solid #007EFF;
    background: #EEF4FA;
  }
  .custom_selected_location p{
    
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 21.4px;
    color: #007EFF;
  }
  .custom_search_input{
    display: flex;
    gap: 8px;
    padding: .5em 1em;
    border-bottom: 1px solid #F3F2EF;

  }
  .custom_search_input > input{
    border: none;
    width: 100%;
  }