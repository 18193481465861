.login_page_cont{
    margin: 0 auto;
    padding: 7.5em 0em;
}
.login_header > h3{
    
    font-size: 2rem;
    font-weight: 700;
    line-height: 41.6px;
    letter-spacing: -0.005em;
    color: #000000;
    margin-bottom: 20px;

}
.login_header > p{
 
    font-size: 1rem;
    font-weight: 400;
    line-height: 26.4px;
    color: #4B4F57;
    margin-bottom: 32px;

}
.login_header a{
    color: #007EFF;
    text-decoration: none;
}
.login_header span{

    font-size: 1rem;
    font-weight: 500;
    line-height: 26.4px;
  
}
.login_header span:hover{
    color: #3398FF;
}
.signup_email > h4,
.signup_pass > h4{
    text-align: left;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 22.4px;
    margin-bottom: 4px;
}
.remember_box{
    display: flex;
    justify-content: space-between;
    margin: 25px 0px;
}
.remember_box_one{
    display: flex;
    gap:8px;
}
.remember_box_one > p{

    font-size: 0.875rem;
    font-weight: 500;
    line-height: 26.4px;
    color: #101928;

}
.remember_box_two{
 
    font-size: 1rem;
    font-weight: 500;
    line-height: 26.4px;
    color: #007EFF;

}
.remember_box_two :hover{
    color: #3398FF;;
}