.onboard_sidebar{
    background:#020712 ;
    width: 383px;
    padding: 1em 3em;
    min-height: 100vh;
    position: sticky;
    top: 0;
}
.onboard_sidebar img{
    width: 100%;
    object-fit: cover;
}
.onboard_sidebar_logo{
    width: 77px;
}
.onboard_sidebar h1{

    font-size: 2.5rem;
    font-weight: 600;
    line-height: 52px;
    letter-spacing: -0.005em;
    color: #FFFFFF;
    margin-top: 40px;
}
.section_two{
    margin-top: 24px;
   
}
.section_two > div{
    margin-bottom: 12px;
    padding: 0.5em 0.75em;
    border-radius: 12px;
    display: flex;
    gap: 8px;
    background-color: #272B32;
    width: fit-content;
   
}
.section_two > div > p{
    color: #FFFFFF;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 22.4px;
    

}
.check {
    width: 24px;
}
.section_three{
    width: fit-content;
    background-color: #272B32;
    padding: 1.5em;
    border-radius: 20px;
    margin-top: 40px;

}
.section_three  p{
    color: #F0E6E6;
    font-size: 1rem;
    font-weight: 400;
    line-height: 26.4px;
    
}
.quote-speaker{
    display: flex;
    gap: 20px;
    margin-top: 15px;
    align-items: center;

}
.quote-speaker > div:nth-child(1){
    display: flex;
    flex-direction: column;

}
.quote-speaker > div > h4{
    
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: -0.005em;
    color: #FFFFFF;

}
