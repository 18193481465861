.more_about_one_cont{
    margin: 0 auto;
    width: 30%;
    padding: 7em 0em;
}
.more_about_one_pp{
    margin:0px 0px 20px;
    cursor: pointer;
    position: relative;
}
.more_about_one_pp_header{

    font-size: 0.875rem;
    font-weight: 500;
    line-height: 22.4px;
    color: #101928;
    margin: 32px 0px 8px;

}
.more_about_one_pp > div{
    
    height: 155px;
    padding: 1.5em 1em;
    border-radius: 8px;
    border: 1px solid #DDD8D0;
    background-color: #FFFFFF;
    text-align: center;

}
.more_about_one_pp span{
    
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.015em;
    color: #77787C;

}
.click_drag_upload{
   
    font-size: 1rem;
    font-weight: 500;
    line-height: 26.4px;
    color:#007EFF ;
    margin: 20px 0px 8px ;

}
.click_svg{
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 18.6px;
    color: #98A2B3;
}

.more_about_one_pp input[type="file"] {
    display: none;
  }
.more_about_user_type > p{
  
    font-size: 1rem;
    font-weight: 500;
    line-height: 26.4px;
    color: #101928;
    margin-bottom: 16px;
}
.more_about_user_type select{
  
        width: 100%;
        height: 44px;
        padding: 0.5em 0.75em;
        border-radius: 6px ;
        border: 1px solid #DDD8D0;
    
}
.more_about_user_type_ioc{
   display: flex; 
   gap: 24px;
   margin-bottom: 20px;
  
}
.more_about_user_type_box {
    display:flex;
    gap: 12px;
    width: 100%;
    height: 46px;
    padding: 0.75em;
    border-radius: 8px;
    border: 1px solid #C3E1FF;
    align-items: center;
}
.more_about_user_type_box.active{
    background-color:#FFFFFF 
}
.more_about_user_type_box  p:nth-child(1){  

    font-size: 1rem;
    font-weight: 500;
    line-height: 26.4px;
    color: #101928;
}
.more_about_user_type_box  p:nth-child(2){  

    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.015em;
    color: #9CA0A5;
}
.more_about_wdyd {
    margin-bottom: 28px;
}
.more_about_wdyd > p{
   
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 22.4px;
    color:#101928 ;
    margin-bottom: 6px;

}
option:disabled {
  
    font-family: Inter;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.015em;
    color: #B8B3A7;
}

.more_about_grid{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    column-gap: 30px;
    row-gap: 20px;
}
.more_about_grid input{
    width: 90%;
    height: 29px;
    padding: 0.5em 0.75em;
    border-radius: 6px;
    border: 1px solid #DDD8D0;
}
.more_about_grid p{

    font-size: 0.875rem;
    font-weight: 500;
    line-height: 22.4px;
    margin-bottom: 6px;
}
.more_about_btn_div{
    margin-top: 32px;
}

.uploaded_image{
    display: flex;
    flex-direction: column;
  
    align-items: center;
}
.uploaded_image > div{
    width: 120px;
}
.uploaded_image img{
    width: 100%;
}
.uploaded_image > button{
    width: 160px;
    height: 34px;
    padding: 0.1em 0.75em;
    border-radius: 4px;
    background: #007EFF;
    color: #FFFFFF;
    font-family: Inter;
    font-size:0.875rem;
    font-weight: 500;
    line-height: 22.4px;
    text-align: center;
    border: 1px solid #007EFF;
}