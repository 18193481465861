.flow_complete_cont{
    margin: 0 auto;
    width: 58%;
    padding: 7.5em 0em;
}
.flow_complete_btm{
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
}
.flow_complete_back{
    display: flex;
    gap: 8px;
    align-items: center;
    width: 85px;
    height: 26.5px;
    padding: .5em .75em;
    border-radius: 8px;
    border: 1px solid #DDD8D0;
    background-color: #FFFFFF;
    

}
.flow_complete_back > h4{
 
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 22.4px;
    text-align: center;
    color: #4B4F57;

}