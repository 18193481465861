.card {
    padding: 2em 1.5em 0em 5em;
    margin: auto;
  }
  
.sum_content{
    padding: 1.5em 2em;
    border: 1px solid #DDD8D0;
    border-radius: 6px;
}
  .title {
    
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.01em;
    margin-bottom: 20px;
  }
  
  .row {
    display: flex;
    align-items: flex-start;
    gap: 15px;
    margin-bottom: 20px;
  }
  
  .row strong {
    width: 150px;
    font-size:0.875rem;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.015em;
    color: #77787C;
  }
  
  .row p, .row  {

    flex: 1;
    font-size: 1rem;
    font-weight: 400;
    line-height: 26.4px;

  }
  
  .tags {
    display: flex;
    gap: 8px;
  }
  
  .tag {
    background: #EEF4FA;
    border: 1px solid #007eff;
    color: #007EFF;
    width: fit-content;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
  }
  