.nav-cont{
    background: #FFFFFF;
    padding: 0.75em 2.5em;
    display: flex;
    height: 42px;
    justify-content: space-between;
    align-items: center;
}
.nav-search-box{
    display: flex;
    gap: 8px;
    align-items: center;
    height: 22px;
    padding: 0.63em 0.75em;
    border-radius: 6px;
    background: #FBFBF9;
    box-shadow: 0px 1px 2px 0px #1018280D;
    width: 50%;
}
.nav-search-box > input{
    border:none;
    width: 100%;
    background: #FBFBF9;
}
.nav-notif{
    display: flex;
    gap: 12px;
}
.nav-notif-set{
    display: flex;
    gap: 6px;
    height: 23px;
    padding: 8px 12px 8px 12px;
    border-radius: 6px;
    border: 1px solid #DDD8D0;

}
.nav-notif-set > p{
    
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: -0.005em;
    color: #344054;
    align-items: center;
    cursor: pointer;
}
.switch-account a{
   
    font-size: 1rem;
    font-weight: 500;
    line-height: 26.4px;
    color: #007EFF;
    text-decoration: none;

}