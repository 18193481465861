.report-pagination-main{
  padding: 0em 2.5em 2.5em;
}
.report-pagination-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #FFFFFF;
    padding: 0.75em 1.5em;
  }
  
  .report-pagination-label{

    font-size: 12px;
    font-weight: 400;
    line-height: 18.6px;
    color: #020712;
  
  }
  #itemsPerPage{
    padding: .5em;
    border-radius: 8px;
    border: 1px solid #DDD8D0;
  }
  
  .report-pagination {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .report-pagination-controls{
    display: flex;
    gap:8px ;
    align-items: center;
  }
  
  .pagination-btn-report {
    padding: 5px 10px;
    border: 1px solid #ccc;
    background: #FFFFFF;
    cursor: pointer;
    border-radius: 8px;
    display: flex;
    gap: 8px;
    align-items: center;
    transition: background-color 0.3s ease;
  }
  
  .pagination-btn-report:disabled {
    cursor: not-allowed;
    background-color: #e0e0e0;
  }
  .pagination-btn-report button{
    border: none;
    background: #FFFFFF;
    margin: 0;
  }
  
  .page-number {
    padding: 5px 10px;
    color: #B8B3A7;
    cursor: pointer;
    background: #fff;
    border: none;
 
  }
  
  .page-number.active {
    
    color: #000000;
    border:1px solid #007EFF;
    border-radius: 6px;
  }
  
  .ellipsis {
    padding: 0 5px;
    color: #888;
  }
  