.progress-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .progress-bar {
    width: 100px;
    height: 4px;
    background-color: #DDD8D0;
    transition: background-color 0.3s;
  }
  
  .progress-bar.active {
    background-color: #F9A410;
  }
  
  .progress-bar:not(:last-child) {
    margin-right: 20px; 
  }
  