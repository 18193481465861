.get-started-main{
    padding:0em 2em 1.5em 2.5em;
    background: #F6F6F6;
}
.get-started-cont{
        
    height: 282px;
    padding:1.5em 1.5em;
    border-radius: 12px;
    background: #FFFFFF;

}
.get-started-header h4{
   
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.01em;
  

}
.get-started-two{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    margin-top: 20px;
    column-gap: 24px;
}
.get-started-two-img{
    height: 120px;
    width: 242px;
}
.get-started-two-img img{
    width: 100%;
    height: 100%;
}
.get-started-two-header p{
  
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 22.4px;
    margin-top: 16px;
    color: #101928;
}
.get-started-two-content p{
           
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 18.6px;
    text-align: left;
    color: #77787C;
    margin: 4px 0px 8px;
    width: 80%;

}
.get-started-learn{
    display: flex;
    align-items: center;
    gap: 8px;
}
.get-started-learn p{
    
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 22.4px;
    color:#007EFF ;

}
.get-started-learn p:hover{
    color: #667185;
}