.news-see-all-main{
   
    background: #F6F6F6;

}
.news-see-all-cont{
    padding:1.5em 1.5em;
    border-radius: 12px;
    background: #FFFFFF;    
}
.news-see-all-header{
   
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}
.news-see-all-header > h4{
        
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.01em;
    color: #000000;

}
.news-see-all-arrow{
    display: flex;
    gap: 8px;
    align-items: center;
}
.news-see-all-arrow > p{
    // font-family: Inter;
    font-size: 1rem;
    font-weight: 600;
    line-height: 26.4px;
    letter-spacing: 0.01em;
    color:#000000;

}
.news-see-all-content{
    display: flex;
    gap: 8px;
    margin-bottom: 20px;
}
// .news-see-all-img{
//     width: 24px;
//     height: 24px;
// }
// .news-see-all-img img{
//     width: 100%;
//     height: 100%;
//     object-fit: cover;
// }
.news-see-all-title > h4{
     // font-family: Inter;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 22.4px;
    color: #101928;
    margin-bottom: 4px;
}
.news-see-all-title > p{

    // font-family: Inter;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 18.6px;
    color: #77787C;
    margin-bottom: 4px;

}
.news-see-all-source{
    display: flex;
}
.news-see-all-source > p{
    // font-family: Inter;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 18.6px;
    color: #77787C;
    padding-right: 0.5em;
    border-right: 1px solid #ECE9E4 ;
}
.news-see-all-source > h5{
  
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 18.6px;
    color:#007EFF ;
    padding-left: 0.5em;

}