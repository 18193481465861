.more_about_four_cont{
    margin: 0 auto;
    width: 36%;
    padding: 7.5em 0em;
}
.more_about_four_header{
 
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 31.2px;
    color: #000000;
    margin: 32px 0px;
}

.more_about_four_text_header{

    font-size: 0.875rem;
    font-weight: 500;
    line-height: 22.4px;
    color: #101928;
    margin-bottom: 4px;
}
.more_about_four_text_helper{
    
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.015em;
    margin: 8px 0px 24px;
    color: #9CA0A5;
}
.more_about_four_text > div{
    height: 102px;
    padding: 0.5em 0.75em;
    border-radius: 6px;
    border: 1px solid #DDD8D0;
    background-color: #FFFFFF;
    display: flex;
    gap: 16px;
    justify-content: space-between;

}
.more_about_four_text textarea{
    border: none;
    width: 100%;
}
.more_about_four_doc > p{
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 22.4px;
    color: #101928;
    margin-bottom: 4px;
}
.more_about_four_doc > h4{
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.015em;
    margin: 8px 0px 24px;
    color: #9CA0A5;
}
.click_drag_uploaded{
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: -0.005em;
    color: #4B4F57;
    margin-top: 12px;
}

.clear_upload{
    display: flex;
    gap: 2px;
    justify-content: center;
    margin-top: 16px;
}
.clear_upload > h4{
   
    font-size: 0.687rem;
    font-weight: 600;
    line-height: 18px;
    color: #B8B3A7;

}