.consumer-sdb-cont{
    width: 256px;
    height: 100vh;
    padding: 2.25em 1em 3.5em;
    background: #FFFFFF;
    border-right: 1px solid #E4E7EC;
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.consumer-sdb-cont-logo{
    padding: 0em 1em;
    margin-bottom: 20px;
}
.consumer-sdb-cont-pg{
    display: flex;
    gap: 12px;
    align-items: center;
    width: 224px;
    margin-bottom: 6px;
    height: 22px;
    padding: 0.75em 1em;
    border-radius: 8px;
    background: #FFFFFF;
    color: #4B4F57;
}
.consumer-sdb-cont-pg.alt{

    background: #000000;
    color: #FFFFFF;
}
.consumer-sdb-cont-pg > h4{
  
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22.4px;
    
}
.consumer-sdb-cont-pg > div{
    height: 20px;
}
.consumer-sdb-cont-sts{
    display: flex;
    justify-content: space-between;
    margin: 40px 0px 28px;
    padding: 0em 1em;
    align-items: center;
}
.consumer-sdb-cont-sts > div{
    display: flex;
    gap: 12px;
}
.consumer-sdb-cont-sts h4{
 
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22.4px;
    cursor: pointer;
}
.consumer-sdb-cont-prf{
    padding-left: 3em;
}
.consumer-sdb-cont-prf > h4{
    
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.015em;
    color: #77787C;
    margin-bottom: 20px;


}
.consumer-sdb-welcome{
    height: 135px;
    padding: 1.25em 1em;
    border-radius: 8px;
    background: #1D2739;

}
.consumer-sdb-welcome > h4{
    
    font-size: 1rem;
    font-weight: 600;
    line-height: 26.4px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    margin-bottom: 6px;
}
.consumer-sdb-welcome > p{
  
    font-size: 0.85rem;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.015em;
    color: #D0D5DD;
    margin-bottom: 22px;
}
.consumer-sdb-wel-btn {
    display: flex;
    gap: 12px;
    align-items: center;
}
.consumer-sdb-wel-btn > p{
    font-size: 0.85rem;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: -0.005em;
    color: #F9A410;
    cursor:pointer;
}
.consumer-sdb-wel-btn > p :hover{
    color: #EB5017;
}
.consumer-sdb-wel-btn > h4{

    font-size: 0.85rem;
    font-weight: 500;
    line-height: 22.4px;
    color: #B8B3A7;
    cursor:pointer;
}
.consumer-sdb-logout{
    margin-top: 30px;
    display:flex;
    justify-content: space-between;;
}
.consumer-sdb-logout-img  {
    width: 40px;
    height: 40px;
   
}
.consumer-sdb-logout-mail{
    max-width: 160px;
    word-break: break-all;
}

.consumer-sdb-logout-img > img{
    width: 100%;
    height: 100%;
    border-radius: 50px;
    object-fit: cover;
}
.consumer-sdb-logout > div{
    display: flex;
    gap: 12px;
    align-items: center;
}
.consumer-sdb-btm{
    margin-bottom: 40px;
}