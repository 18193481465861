.forgot_pass_cont{
    margin: 0 auto;
    padding: 7.5em 0em;
}
.forgot_pass_cont > h1{
 
    font-size: 2rem;
    font-weight: 700;
    line-height: 41.6px;
    letter-spacing: -0.005em;
    color: #000000;
    margin-bottom: 20px;

}
.forgot_pass_cont > p{
  
    font-size: 1rem;
    font-weight: 400;
    line-height: 26.4px;
    color: #4B4F57;
    margin-bottom: 32px;
}