
.verify_mail_content{
  margin: 0 auto;
  width: 23%;
  padding: 7.5em 0em;
}
.verify_mail_content > h3{

  font-size:2rem;
  font-weight: 700;
  line-height: 41.6px;
  letter-spacing: -0.005em;
  color: #000000;
  margin: 32px 0px 20px 0px;

}
.verify_mail_content > p{

  font-size: 1rem;
  font-weight: 400;
  line-height: 26.4px;
  margin-bottom: 32px;

}
.verify_btns{
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
}
.resend_otp_btn{
  width: 160px;
  height: 44px;
  padding: 0.5em 1em;
  border-radius: 8px;
  border: 1px solid #007EFF;
  background-color: #FFFFFF;
  font-family: Inter;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 22.4px;
  color: #007EFF;


}
.resend_otp_btn.alt{
  color: #DDD8D0;
  border: 1px solid #FFFFFF;
}

.otp-input {
    width: 48px;
    height: 48px;
    font-size: 1.25rem;
    text-align: center;
    margin-right: 8px;
    border: 1px solid #DDD8D0;
    border-radius: 4px;
}
.otp_cntdw{
  
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.015em;
    text-align: left;
    color: #77787C;
    margin-top: 16px;
}
