.similar-resource-main{

    padding: 2.5em ;
    border-radius: 12px;

}

.similar-resource-body{
    padding: 2em;
    background: #FFFFFF;
    border: 1px solid #E4E4E7;
    border-radius: 12px;
}

.similar-resource-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
}
.similar-resource-header > div{
    display: flex;
    align-items: center;
}
.similar-resource-header > div h4{
        
    font-size: 1rem;
    font-weight: 500;
    line-height: 26.4px;
    color: #000000;
    
   
}

.similar-resource-header > h4{
           
  
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 31.2px;
    color: #000000;
}
.similar-resource-content{
    margin-bottom: 24px;
    display: grid;
    column-gap: 24px;
    grid-template-columns: 70% 10% 10% 10%;
}
.similar-resource-img{
    display: flex;
    gap: 12px;
}

.similar-resource-img > div  h4{
    
    font-size: 1rem;
    font-weight: 500;
    // line-height: 26.4px;
    margin-bottom: 4px;
    color: #101928;
    cursor: pointer;
}

.similar-resource-content li{
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.015em;
    color: #9CA0A5;
}
.similar-resource-content p{
   
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.015em;
    color: #77787C;
    
}
