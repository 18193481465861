.upgrade-main{
    padding: 0em 2em 3.8em 2.5em;
}
.upgrade-cont{
    // width:1,046px
    height: 158px;
    padding: 2.5em;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #020712;
    box-shadow: 0px 4px 6px -2px #0000000F;
    // box-shadow: 0px 12px 16px -4px #4646461A;
}
.upgrade-cont h4{
    // font-family: Inter;
    font-size: 1.75rem;
    font-weight: 600;
    line-height: 36.4px;
    color: #F0F2F5;
    margin-bottom: 16px;

}
.upgrade-cont p{
    // font-family: Inter;
    font-size: 1rem;
    font-weight: 400;
    line-height: 26.4px;
    color: #D0D5DD;

}
.upgrade-cont-btn > button{
    // font-family: Inter;
    font-size: 1rem;
    font-weight: 500;
    line-height: 26.4px;
    color: #FFFFFF;
    width:152px;
    height: 56px;
    padding: 1em 1.5em;
    border-radius: 12px;
    background: #007EFF;
    border: 1px solid #007EFF;

}