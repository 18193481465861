.more_about_two_cont{
    margin: 0 auto;
    width: 26%;
    padding: 3.5em 0em;
}
.more_about_prefer{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    row-gap: 10px;
    margin-bottom: 32px;
}
.more_about_prefer > div{
  display: flex;  
  gap: 12px;
}
.more_about_imgch{
    width: 24px;
}
.more_about_imgch > img{
    width: 24px;
}
.more_about_prefer p{
   
    font-size: 1rem;
    font-weight: 500;
    color: #101928;

}
.more_about_two_text  {
    margin: 32px 0px 24px;
}
.more_about_two_text > p:nth-child(1){
        
    font-size: 1rem;
    font-weight: 500;
    line-height: 26.4px;
    margin-bottom: 10px;

}
.more_about_two_text > p:nth-child(2){

    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.015em;
    color: #9CA0A5;
   
}
.more_about_prefer > p{
 
    font-size: 1rem;
    font-weight: 500;
    line-height: 26.4px;
    color: #101928;
}