.report-view-cont{
    padding: 3em 2.5em 0em;
    
}
.report-view-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
}
.report-view-btn-cont{
    display: flex;
    gap: 12px;
    align-items: center;
}
.report-view-btn-one{
    display: flex;
    align-items: center;
    background: #FFFFFF;
    height: 27px;
    border: 1px solid #DDD8D0;
    padding: .5em .75em;
    border-radius: 8px;
    gap: 8px;
    cursor: pointer;

}
.report-view-btn-one:hover{
    background: #F3F2EF;
}
.report-view-btn-one h4{
   
    border: none;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 22.4px;
    color: #344054;
}


.report-view-btn-two{
    height: 43px;
    padding:.5em 1em;
    gap: 10px;
    border-radius: 8px;
    color: #FFFFFF;
    background: #007EFF;
    border: 1px solid #007EFF;
}
.report-view-btn-two:hover{
    background: #3398FF;
}
.report-view-content{
    display: flex;
    gap: 24px;
}
.report-view-content-one{
    width: 64.5%;
    border: 1px solid #E4E7EC;
    padding: 2em 1.5em;
    background: #FFFFFF;
    border-radius: 10px;
}
.report-view-details{
    display: grid;
    grid-template-columns: 148px auto;
    margin-bottom: 12px;
}
.report-view-details h4{

    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.015em;
    color: #77787C;
    
}
.report-view-details p{
    
    font-size:1rem;
    font-weight: 400;
    line-height: 26.4px;
    text-align: left;
    color: #101928;; 
}  

.report-view-content-two{

    padding: 1.5em;
    background: #FFFFFF;
    border: 1px solid #E4E7EC;
    border-radius: 10px;
    width: 25%;
}
.report-view-content-line{
    display: flex;
    justify-content: space-between;
    
}
.report-view-content-line  > div{

    display: flex;
    gap: 8px;
    align-items: center;
    margin-bottom: 18px;
}
.report-view-content-line h4{
 
    font-size: 14px;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.015em;
    color: #4B4F57;

}
.report-view-content-line p{
  
    font-size: 1rem;
    font-weight: 500;
    line-height: 26.4px;
    color: #101928;
    
}
.report-right-divider{
    border-top: 1px solid #E4E7EC;
    margin-bottom: 18px;
}

.report-bookmark {

    margin-top: 22px;
    height: 26px;
    padding: .5em .75em;
    border-radius: 8px;
    border: 1px solid #007EFF;
    display: flex;
    gap: 6px;
    align-items: center;
    cursor: pointer;
    justify-content: center;
}
.report-bookmark h4{
    border: none;
    // background: #FFFFFF;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 22.4px;
    color: #007EFF;
}
.report-bookmark:hover{
    background: #EEF4FA;
}