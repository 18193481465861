.my-actvy-main{
    padding:2em 2.5em ;
    background: #F6F6F6;
    border-radius: 12px;
}
.my-actvy-cont{
    height: 120px;
    padding: 1.25em 1.5em;
    background: #FFFFFF;
    border-radius: 12px;
}
.my-actvy-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.my-actvy-header > h4{
      
   
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.01em;


}
.actvy-see-all-btn{
    display: flex;
    gap: 6px;
    align-items: center;
    height: 27px;
    padding: 0.5em 0.755em;
    border-radius: 8px;
    border: 1px solid #DDD8D0;
}
.actvy-see-all-btn > p{
    
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 22.4px;
    text-align: center;

}
.my-actvy-grid{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    column-gap: 40px;
    margin-top: 20px;
}
.my-actvy-content {
    display: flex;
    border-right: 1px solid #ECE9E4;
    gap: 8px;
}
.my-actvy-content:nth-child(4){
    border: none;
}
.my-actvy-content h4{
    
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 31.2px;
    color: #101928;
    margin-bottom: 4px;
}
.my-actvy-content p{
       
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.015em;
    color: #77787C;
}