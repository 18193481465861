.more_about_three_cont{
    margin: 0 auto;
    width: 26%;
    padding: 3.5em 0em;
}
.more_about_three_wdyl{
    
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 22.4px;
    color: #101928;
    margin: 32px 0px 4px;

}
.more_about_account_type{
    margin-top: 32px;
}
.more_about_account_type > div{
    display: flex;
    gap: 12px;
    margin-bottom: 13px;
}
.more_about_account_type > div >  h4{
    
    font-size: 1rem;
    font-weight: 500;
    line-height: 26.4px;
 
    color: #101928;
}
.more_about_account_type  span{
   
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.015em;
    color: #9CA0A5;


}
.more_about_account_type > p{
  
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 22.4px;
    color: #101928;
    margin-bottom: 8px;

}