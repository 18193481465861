.termsandprivacy{
    color: #4B4F57;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 18.6px;
    text-align: center;

}
.termsandprivacy a{
    color: #4B4F57;
}