

.pricing-header{
    padding: 0em 1em;
}
.pricing-header h1{
 
    
    font-size: 1.75rem;
    font-weight: 600;
    line-height: 36.4px;
    text-align: center;
    margin:16px 0px 32px;

}
.pricing-list{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 10px;
    padding: 0em 1em;
}
.pricing-list-one-cont,
.pricing-list-two-cont,
.pricing-list-three{
    width: 244px;
}
.pricing-list-one-cont{
   
    padding: 1.5em;
    background:rgba(238, 244, 250, 1);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 252px;
}

.pricing-list-one h2{
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 62.4px;
    letter-spacing: -0.04em;
    color:#000000;
    margin-bottom: 20px;
}


.pricing-list-one h6{
    padding: 0.13em 0em;
    width: 56px;
    text-align: center;
    border-radius: 16px;
    background:#101928;
    color:#FFFFFF;
    margin-bottom: 20px;
    
    font-size: 0.7em;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    
}

.pricing-list-one > div{
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 8px;

}

.pricing-list-one div > p{
    
    font-size: 0.875em;
    font-weight: 400;
    line-height: 21px;
    color: #272b32
}

.pricing-list-one-cont > h4{

    height:44px;
    padding: 0em 9.8em;
    background: rgba(16, 25, 40, 1);
    border-radius: 8px;
    color:#FFFFFF;
    
    font-size: 0.7em;
    font-weight: 500;
    line-height: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    
}
.pricing-list-two-cont{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background:rgba(238, 244, 250, 1);
    padding: 1.5em;
    border-radius: 12px;
    height: 334px;
}

.pricing-list-two  h5{
    
    
    font-size: .8rem;
    font-weight: 400;
    line-height: 21px;
    color: #F3F2EF;
}
// .pricing-list-two{
  
    
// }

.pricing-list-two h2{
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 62.4px;
    letter-spacing: -0.04em;
    color:#000000;
    
}


.pricing-list-two h6{
    padding: 0.13em 0em;
    width: 61px;
    text-align: center;
    border-radius: 16px;
    background:rgba(15, 151, 61, 1);
    color:rgba(255, 255, 255, 1);
    margin-bottom: 20px;
    
    font-size: 0.7em;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    
    
}

.pricing-list-two div{
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 8px;
}

.pricing-list-two div > p{
    
    font-size: 0.875em;
    font-weight: 400;
    line-height: 21px;
   
    color: #272b32
}

.pricing-list-two-cont > h4{
 
    height:44px;
    padding: 0em 6.77em;
    background: rgba(0, 126, 255, 1);
    border-radius: 8px;
    color:#FFFFFF;
    font-size: 0.7em;
    font-weight: 500;
    line-height: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    
}


.pricing-list-three-cont{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1.5em;
    background:rgba(238, 244, 250, 1);
    border-radius: 12px;
    height: 396px;
}

.pricing-list-three h2{
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 62.4px;
    letter-spacing: -0.04em;
    color:#000000;
    
}

.pricing-list-three h5{
    
    
    font-size: .8rem;
    font-weight: 400;
    line-height: 21px;
    color: #F3F2EF;
}
.pricing-list-three h6{
    padding: 0.13em 0em;
    width: 83px;
    text-align: center;
    border-radius: 16px;
    background:rgba(243, 162, 24, 1);
    color:rgba(0, 0, 0, 1);
    margin-bottom: 33px;
    font-size: 0.7em;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    
}

.pricing-list-three div{
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 8px;
}

.pricing-list-three div > p{
    
    font-size: 0.875em;
    font-weight: 400;
    line-height: 21px;
   
    color: #272b32
}

.pricing-list-three-cont > h4{
   
    height:44px;
    padding: 0em 5em;
    background: rgba(0, 126, 255, 1);
    border-radius: 8px;
    color:#FFFFFF;
    font-size: 0.7em;
    font-weight: 500;
    line-height: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    
}
.right-content{
   
    display: flex;
    justify-content: end;
}
.loader-box{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 192px;
}

// @media only screen and (max-width: 768px) {
//     .pricing-body{
//         padding: 6.25em 2em;
//     }
//     .pricing-list {
//         gap: 18px;
//         justify-content: center;
//     }
   
//     .pricing-list-one > h4,
//     .pricing-list-two > h4,
//     .pricing-list-three > h4{
//         padding: 0em; 
//         width: 171px
//     }
//     .pricing-list-one,
//     .pricing-list-two,
//     .pricing-list-three {
//         width: 219px
//     }
//     .pricing-list-one{
//         padding: 1.5em 1.5em
//     }
// }


// @media only screen and (max-width: 480px) {
//     .pricing-header h1 {
//         text-align: center;
//     }
//     .pricing-list {
//         flex-direction: column;
//     }
//     .pricing-list-one, 
//     .pricing-list-two, 
//     .pricing-list-three {
//         margin-top: 24px;
//     }
//     .pricing-list-one > h4,
//     .pricing-list-two > h4,
//     .pricing-list-three > h4{
    
//         width: 217px
//     }
// }