.reports_main{
    padding: 0em 2.5em 0em;
    

}
.reports_content_cont{
    background: #FFFFFF;
    padding: 1.5em;
    display: flex;
    gap: 16px;
    border: 1px solid #ECE9E4;
}
.reports_content_cont:nth-child(1){
    border-radius: 10px 10px 0px 0px;
}
.reports_title_cont{
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
}
.reports_title_cont > div{
    gap: 24px;
    display: flex;
}
.reports_title_cont > h5{
   color: #007EFF;
    font-size: 12px;
    font-weight: 400;
    line-height: 19.2px;
    letter-spacing: 0.1em;
   

}
.reports_content_main{
    width: 100%;
}
.reports_content_main > h4{
   
    font-size: 1rem,;
    font-weight: 500;
    line-height: 26.4px;
    color: #000000;
    margin-bottom: 4px;
    cursor: pointer;
}
.reports_content_main > h5{
      
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 400;
    line-height: 23.1px;
    color: #77787C;
    margin-bottom: 8px;
}
.reports_content_main > p{
        
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.015em;
    margin-bottom: 16px;

}
.reports_add_info {
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.reports_tags{
    display: flex;
    gap: 16px;
    align-items: center;
}
.reports_tags > div{
    display: flex;
    gap: 8px;
    align-items: center;
}
.reports_view{
    display: flex;
    gap: 8px;
    align-items: center;
}

.reports_add_info h4{
   
    font-size: 14px;
    font-weight: 400;
    line-height: 21.7px;
    color: #4B4F57;
}