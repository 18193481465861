.complete_contributor_cont{
    margin: 0 auto;
    width: 60%;
    padding: 7.5em 0em;
}
.credibility_box{
    background-color: #FFFFFF;
    border-radius: 12px;
    margin-top: 12px;
}
.credibility_box_header{
    padding: 1.25em 1.5rem;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.01em;
    color: #000000;
    border-bottom: 1px solid #ECE9E4;

}
.credibility_box_content{
    padding: 1.25em 1.5rem;
    margin-bottom: 32px;
}
.credibility_box_content > h4{
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 22.4px;
    color: #101928;
    margin-bottom: 5px;
}
.credibility_box_content > p{
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.015em;
    color: #77787C;
}