.see-all-box-main{
   
    background: #F6F6F6;

}
.see-all-box-cont{
    padding:1.5em 1.5em;
    border-radius: 12px;
    background: #FFFFFF;
    
}
.see-all-box-header{
   
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}
.see-all-box-header > h4{
        
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.01em;
    color: #000000;

}
.see-all-box-arrow{
    display: flex;
    gap: 8px;
    align-items: center;
}
.see-all-box-arrow > p{
    // font-family: Inter;
    font-size: 1rem;
    font-weight: 600;
    line-height: 26.4px;
    letter-spacing: 0.01em;
    color:#000000;

}
.see-all-box-two{
    display: flex;
    gap: 24px;
}

.see-all-box-two-img{
   
    overflow: hidden;
    margin-bottom: 16px;
}
.see-all-box-two-img img{
    width: 100%;
    height: 100%;
    border-radius: 4px 4px 0px 0px;
    object-fit: cover;
    border: 1px solid #ECE9E4;
}

.see-all-box-two-type{
    display: flex;
    gap: 8px;
    margin-bottom: 5px;
}

.see-all-box-two-type > p{
    // font-family: Inter;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 19.2px;
    letter-spacing: 0.1em;
    color: #77787C;

}
.see-all-box-two-title{
    
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 22.4px;
    color:#101928;
    margin-bottom: 4px;
   
}
.see-all-box-two-country{
    // font-family: Inter;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22.4px;
   color: #007EFF;

}
.see-all-box-updated{
    // font-family: Inter;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 18.6px;
    color:#77787C;
    margin-top: 4px;

}
.empty-state{
    text-align: center;
    width: 100%;
}
.empty-state p{
    text-align: center;
    font-size: 1rem;
    font-weight: 400;
    line-height: 26.4px;
    color: #9CA0A5;

}