.news_page_cont{
    padding: 0em 2.5em 1.5em;
}
.news_page_main{
    width: auto;
    padding: 1.5em;
    background: #FFFFFF;
    border-radius: 12px;
    height:auto;
}
.news_page_main > h2{

    font-size: 1.25rem;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.01em;
    color:#000000 ;
    margin-bottom: 16px;

}
.news_page_content {
    width: auto;
    height: auto;
    padding: 1em;
    border-radius: 4px;
    border: 1px solid #DDD8D0;

}
.news_page_content:hover{

    border: 1px solid #007EFF;
}
.news_page_content_main  h4{
 
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 22.4px;
    margin-bottom: 4px;
    height: 48px;
    cursor: pointer;

}
.news_page_content_main  p{
        
    height: 18px;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 18.6px;
    color: #77787C;
    margin-bottom: 8px;
}
.news_page_tags{
    display: flex;
    gap: 8px;
}
.news_page_tags > h4{
    
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 18.6px;
    text-align: left;
    color: #77787C;
    margin-bottom: 10px;

}
.news_page_tags > h4:nth-child(1){
  
    font-size: 12px;
    font-weight: 400;
    line-height: 18.6px;
    text-align: left;
    color: #007EFF;
    border-right: 1px solid #ECE9E4;
    padding-right: 8px;
   
}

.news_page_day{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.news_page_day > h4{

    font-size: 0.75rem;
    font-weight: 400;
    line-height: 18.6px;
     color: #77787C;
}
.news_page_grid{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    column-gap: 24px;
    row-gap: 24px;
    height: auto;
}
.news_page_box{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.news_page_pagi{
    display: flex;
    justify-content:end;
    margin-top: 18px;
}