.page-header-cont{
    background: #FFFFFF;
    padding: 0.75em 2.5em;
    display: flex;
    gap: 24px;
    border-top: 1px  solid #E4E7EC;
    border-bottom: 1px  solid #E4E7EC;

}
.pg-divider{
    border-left: 1px solid #E4E7EC;
    
}
.pg-header-name {
    display: flex;
    gap: 10px;
    align-items: center;
}
.pg-header-name > div{
    display: flex;
    gap: 4px;
}
.pg-header-name h4{
    color: #007EFF;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 22.4px;
}
.pg-header-name h5{
    
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 22.4px;
    color: #B8B3A7;
    text-align: center;
 
}
.pg-header-name p{
   
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 22.4px;
    color: #9CA0A5;

}