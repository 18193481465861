.greeting-box-cont{
    padding: 1.5em 2.5em;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.greeting-box-cont-one h4{
    
    font-size: 1.75rem;
    font-weight: 600;
    line-height: 36.4px;
    color: #000000;
    margin-bottom: 4px;
}
    
.greeting-box-cont-one h4 > span{
    
    font-size: 1.75rem;
    font-weight: 600;
    line-height: 36.4px;
    color: #007EFF;
}
.greeting-box-cont-rfr > p{
    
    font-size: 16px;
    font-weight: 400;
    line-height: 26.4px;
    color: #77787C;

}
.greeting-box-cont-rfr{
    display: flex;
    gap: 12px;
}
.greeting-box-cont-rfr > div{
    display: flex;
    gap: 8px;
    align-items: center
}
.greeting-box-cont-rfr > div > p{
  
    font-size: 1rem;
    font-weight: 500;
    line-height: 26.4px;
    color: #007EFF;

}
.greeting-box-cal{
    background: #F3F2EF;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.greeting-box-date{
    display: flex;
    gap: 12px;
}
.greeting-box-date {

    height: 45px;
    padding: 1em 1.5em;
    border-radius: 12px;
    border: 1px solid #ECE9E4;
    background: #FFFFFF;

}
.greeting-box-day > p{

    font-size: 0.75rem;
    font-weight: 400;
    line-height: 18.6px;
    color: #77787C;

}
.greeting-box-day > h4{

    font-size: 1rem;
    font-weight: 600;
    line-height: 26.4px;
    color: #4B4F57;

}
.new-contrbtn{
    display: flex;
    align-items: center;
    height: 44px;
    padding: 0.5em 0.75em;
    border-radius: 8px;
    background-color: #007EFF;
    border: 1px solid #007EFF;
    outline: none;
    gap: 8px;
}
.new-contrbtn p{
  
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 22.4px;
    color: #FFFFFF;

}
.cont_modal_header{
    text-align: center;
    margin-bottom: 32px;
}
.cont_modal_content{
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    // align-items: center;
}
.cont_modal_header h2{
    font-size: 28px;
    font-weight: 600;
    line-height: 36.4px;
    margin-bottom: 12px;
}

.cont_modal_header p{
    
    font-size: 1rem;
    font-weight: 400;
    line-height: 26.4px;
    color: #4B4F57;
    text-align: center;
   

}

.cont_modal_content > div{
    display: flex;
    gap: 12px;
}

.cont_modal_content h4{
       
    font-size: 1rem;
    font-weight: 500;
    line-height: 26.4px;
    color: #101928;
}

.cont_modal_content p{
   
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.015em;
    color: #9CA0A5;
}
.cont_modal_sub{
        
    font-size: 1rem;
    font-weight: 500;
    line-height: 26.4px;
    color: #101928;
    margin-bottom: 24px;
}
.cont_modal_button{
    
    height:56px;
    padding: 1em 1.5em;
    border-radius: 12px;
    width: 100%;
    background: #007EFF;
    border: 1px solid #007EFF;
    color: #FFFFFF;
    font-size: 1rem;
    font-weight: 500;
    line-height: 26.4px;


}