.banner-cont{
   
    height: 26px;
    padding: .5em 1.5em;
    background: #C3E1FF;
}
.banner-cont p{
   
    font-size: 1rem;
    font-weight: 500;
    line-height: 26.4px;
    text-align: center;
    color: #0C2D6F;
}