.jump-back-main{
    padding:1.5em 2em 1.5em 2.5em;
    background: #F6F6F6;

}
.jump-back-cont{
        
    height: 164px;
    padding:1.5em 1.5em;
    border-radius: 12px;
    background: #FFFFFF;

}
.jump-back-header{
   
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
}
.jump-back-header > h4{
        
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.01em;
    color: #000000;

}
.jump-back-arrow{
    display: flex;
    gap: 24px;
}
.jump-back-two{
    display: flex;
    gap: 24px;
}
.jump-back-two-box.hidden{
    display: none;
}
.jump-back-two-box{
    display: flex;
    gap: 16px;
    padding: .5em;
    border: 1px solid #DDD8D0;
    border-radius: 4px;
    width: 30%;
}
.jump-back-two-img{
    width: 100px;
    height: 100px;
    overflow: hidden;
}
.jump-back-two-img img{
    width: 100%;
    height: 100%;
    // object-fit: cover;
    // border-radius: 4px;
}
.jump-back-two-content{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.jump-back-two-content > p{
    
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 22.4px;
    color:#101928;
   
}
.jump-back-two-content > div{
    display: flex;
    gap:8px;
}
.jump-back-two-content > div > p{
    // font-family: Inter;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 19.2px;
    letter-spacing: 0.1em;
    color: #77787C;

}